@import "~bootstrap/scss/bootstrap.scss";
@import "~swiper/css/swiper.css";
@import "~animate.css/animate.min.css";
@import "~lightgallery.js/dist/css/lightgallery.css";
@import "~react-modal-video/scss/modal-video.scss";
@import "../css/icons.min.css";

@import "variables";
@import "helper";
@import "common";
@import "preloader";
@import "header";
@import "slider";
@import "support";
@import "section-title";
@import "product";
@import "blog";
@import "footer";
@import "banner";
@import "suscribe";
@import "about";
@import "image-slider";
@import "testimonial";
@import "brand-logo";
@import "timers";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "compare";
@import "checkout";
@import "contact";
@import "blog-details";
@import "login-register";
@import "my-account";
@import "404";
@import "video-popup";
.offcanvas-widget-area {
  margin-top: auto;
  margin-bottom: 246px;
}
.login-register-form.custom {
  input {
    margin-bottom: 10px !important;
  }
}

.login-register-form {
  width: 90%;
  max-width: 400px;
  padding: 20px;
  margin: 0px auto;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  .mb-3 {
    margin: 0 !important;
    .form-label {
      margin-bottom: 3px;
      font-weight: 500;
      font-family: "Ubuntu", sans-serif;
      font-size: 13px;
    }
    .form-control {
      padding: 10px;
      font-size: 13px;
      &:focus {
        box-shadow: 0 0 2px green;
      }
    }
  }
  .submit-btn {
    border: 0;
    cursor: pointer;
    background-color: #b66dff;
    border-color: #b66dff;
    padding: 4px 0;
    color: white;
  }
  p {
    margin: 0;
    font-size: 14px;
    line-height: 17px;
    a {
      color: #0000ffa8;
      font-weight: 600;
      cursor: pointer;
      font-family: sans-serif;
    }
  }
}
.delete-user-modal {
  .modal-content {
    background-color: #f3f3f3;
  }
}
.dropzone {
  height: 200px;
  border: 1px dotted;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview {
  position: relative;
  p {
    position: absolute;
    top: 10px;
    font-size: 30px;
    left: 10px;
    font-weight: 700;
    cursor: pointer;
  }
  img {
    margin: 10px 0;
    height: 200px;
    display: block;
    width: auto;
    opacity: 0.8;
  }
}
.multiple-preview {
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  .preview {
    position: relative;
    img {
      height: 200px;
      width: 100%;
      opacity: 0.8;
    }
    p {
      position: absolute;
      top: 10px;
      font-size: 30px;
      right: 10px;
      font-weight: 700;
      cursor: pointer;
    }
  }
}
